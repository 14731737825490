<template>
  <div class="brand-investment-wrap">
    <div v-if="dialogType === 0">
      <div class="input-wrap">
        <div class="title">跟进人： </div>
        <div
          style="width: 100%;"
          class="cascader-style height36-input"
        >
          <el-select
            popper-class="w-block-select-down yellow-select-panel"
            v-model="followUserId"
            @change="changeFollow"
            placeholder="请选择"
            width="280px;"
            size='small'
          >
            <el-option
              v-for="item in followList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div v-if="dialogType === 1">
      <div class="input-wrap">
        <div
          class="title"
          style="width: 120px;"
        >计划时间范围： </div>
        <div class="height36-input">
          <el-date-picker
            style="width: 250px;"
            popper-class="black-el-date-picker"
            v-model="timeVal"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="btns-wrap">
      <div class="btns">
        <span
          class="sure"
          @click="sure"
        >确定</span>
        <span
          class="cancal"
          @click="cancalClick()"
        >取消</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      followUserId: '',
      followUserName: '',
      followList: [],
      timeVal: ''
    }
  },
  props: {
    projectId: {
      type: Number
    },
    dialogType: {
      type: Number
    },
    selectTaskIds: {
      type: Array
    }
  },
  watch: {
    dialogType (n, o) {
      if (n !== o) {
        this.timeVal = ''
      }
    }
  },
  mounted () {
    console.log(this.dialogType)
    this.getFollow()
  },
  methods: {
    changeFollow (val) {
      const obj = this.followList.find(item => {
        return item.value === val
      })
      this.followUserName = obj.label
    },
    getFollow () {
      this.axios.post(api.getFollowList, { projectId: this.projectId, isShow: 1 })
        .then((res) => {
          this.followList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
        })
    },
    sure () {
      const data = {}
      data.projectId = this.projectId
      let apiStr = ''
      if (this.dialogType === 0) {
        data.followUserId = this.followUserId
        data.followUserName = this.followUserName
        apiStr = api.batchUpdateUser
      } else {
        apiStr = api.batchUpdatetime
        if (!this.timeVal) {
          this.$message({
            type: 'warning',
            message: '请选择计划时间！'
          })
          return
        }
        data.planStartTime = dayjs(this.timeVal[0]).format('YYYY-MM-DD')
        data.planFinishTime = dayjs(this.timeVal[1]).format('YYYY-MM-DD')
      }
      data.taskIds = this.selectTaskIds
      this.axios.post(apiStr, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.$emit('editStatusEmit')
          }
        })
    },
    cancalClick () {
      this.$emit('handleClose')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-investment-wrap
  .input-wrap
    display flex
    align-items center
    .title
      width 80px
      color #fff
      font-size 14px
  .btns-wrap
    display flex
    margin-top 75px
    .btns
      margin-left auto
      span
        display inline-block
        width 88px
        height 36px
        line-height 36px
        text-align center
        color #fff
        border-radius 4px
        cursor pointer
        &.sure
          background-color #F89407
          &:hover
            background-color #DB8307
        &.cancal
          background-color #373A43
          margin-left 24px
</style>
