<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-follow-manage-wrap"
  >
    <!-- <div class="w-follow-head">
      <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          tag="span"
          :to="{ path: '/implement' }"
        >招商实施管理</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">招商跟进管理</span>
      </div>
      <div
        class="w-follow-btn"
        v-if="hasPermission"
      >
        <span
          class="w-drop-btn"
          @click="addInvestment(0)"
        >添加招商任务</span>
        <span
          class="w-export-btn"
          @click="exportInvestment()"
        > <i class="icon-export"></i> 导出</span>
      </div>
    </div> -->
    <div
      class="w-follow-manage-main"
      v-if="hasPermission"
    >
      <div class="w-berth-screen">
        <div class="w-screen-list">
          <div class="w-screen-top">
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">品牌名称：</span>
              <input
                class="el-input__inner"
                type="text"
                v-model="brandName"
                placeholder="请输入品牌关键字"
              />
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">品牌业态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="formatStr"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in formatList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">品牌跟进人：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="followId"
                @change="changeFollow"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in followList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">跟进状态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="statesStr"
                @change="changeStates"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in states"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="w-screen-bottom">
            <div class="w-input-item w-input-sty">
              <span class="w-type-name w-type-alignment">意向落位铺位：</span>
              <el-cascader
                v-model="selectFloor"
                popper-class="black-select-panel"
                :options="filterFloorArr"
                change-on-select
                @change="changeFloor"
                ref="myCascader"
                filterable
                clearable
              ></el-cascader>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">审批状态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="approval"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in approvalLsit"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="w-input-item w-input-sty">
              <span class="w-type-name">主次力店：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="storeType"
              >
                <el-option
                  v-for="item in storeTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">超时状态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="overTime"
                placeholder="请选择超时项"
              >
                <el-option
                  v-for="item in overtimeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty w-input-time time-select">
              <el-select
                popper-class="w-block-select-down"
                v-model="timeType"
                placeholder="创建时间"
              >
                <el-option
                  v-for="item in timeTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span
                v-if="timeValue && timeValue.length === 0 || timeValue === null"
                class="time-value-placeholder"
              >{{timeValuePlaceHolder[timeType]}}</span>
              <el-date-picker
                style="width: 190px;"
                v-model="timeValue"
                @change="handleTimeValue"
                clearable
                type="daterange"
                popper-class="black-el-date-picker"
                :range-separator="timeValue && timeValue.length !== 0?'-':''"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="w-screen-bottom">

            <div
              class="w-query-btn"
              @click="searchBtn()"
            >
              <span>查询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-singed-table">
        <div class="header-content">
          <h3 class="w-title-gradients">品牌招商列表</h3>
          <div class="btns">
            <span
              class="setting-follow-user"
              @click="dialogShow(0)"
            >指定跟进人</span>
            <span
              class="setting-brand-task"
              @click="addTaskBrand(1)"
            >发布招商任务</span>
            <span
              class="setting-brand-follow"
              @click="addTaskBrand(2)"
            >添加品牌跟进</span>
            <span
              class="setting-plan-time"
              @click="dialogShow(1)"
            >设置计划时间</span>
            <span
              class="setting-export"
              @click="exportInvestment()"
            >导出</span>
          </div>
        </div>
        <div class="table-content">
          <el-table
            class="tbs-black"
            :data="brandList.dataList"
            style="width: 100%"
            :row-class-name="setClassName"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="40"
              align="center"
            >
            </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form
                  label-position="left"
                  inline
                  class="demo-table-expand"
                  v-for="(item, index) in props.row.children"
                  :key="index"
                >
                  <el-form-item label="意向楼宇楼层：">
                    <span>{{ item.buildingFloors }}</span>
                  </el-form-item>
                  <el-form-item label="意向落位铺位：">
                    <span>{{ item.berthNo }}</span>
                  </el-form-item>
                  <el-form-item label="主次力店：">
                    <span>{{item.mainStore === '1' ? '主力店' : (item.mainStore === '2' ? '次主力店' : (item.mainStore === '0' ? '普通店' : '-'))}}</span>
                  </el-form-item>
                  <el-form-item label="规划业态：">
                    <span>{{ item.industry1 || '-' }}</span>
                  </el-form-item>
                  <el-form-item label="招商品牌级次：">
                    <span>{{ item.brandClass ? item.brandClass : '-' }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
              prop="brandName"
              label="品牌名称"
              width="200"
            >
              <template slot-scope="scope">
                <span
                  @click="toBrandDetail(scope.row)"
                  class="brandName ellipsis"
                  :title="scope.row.brandName ? scope.row.brandName : '-'"
                >{{scope.row.brandName ? scope.row.brandName : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="industry"
              label="品牌业态"
              width="140"
            >
              <template slot-scope="scope">
                <span
                  class="industry ellipsis"
                  :title="scope.row.industry ? scope.row.industry : '-'"
                >{{scope.row.industry ? scope.row.industry : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="followUserName"
              label="品牌跟进人"
              width="140"
            >
              <template slot-scope="scope">
                <span class="ellipsis">
                  {{scope.row.followUserName || '-'}}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="brandName"
              label="计划时间"
              width="210"
            >
              <template slot-scope="scope">
                {{scope.row.brandBerthTime || '-'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="taskState"
              label="品牌跟进状态"
              width="130"
            >
              <template slot-scope="scope">
                <i
                  class="status"
                  :style="{background: scope.row.color}"
                ></i><span>{{scope.row.taskState ? scope.row.taskState : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="brandName"
              label="审批状态"
              width="100"
            >
              <template slot-scope="scope">
                <span>{{scope.row.approvalStatus ? scope.row.approvalStatus : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="lastFollowDate"
              label="最新跟进时间"
              width="150"
            >
              <template slot-scope="scope">
                {{scope.row.lastFollowDate || '-'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="lastFollowMsg"
              label="最新跟进信息"
              width="200"
            >
              <template slot-scope="scope">
                <span class="lastFollowMsg ellipsis">{{scope.row.lastFollowMsg ? scope.row.lastFollowMsg : '-'}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="planStartTime"
              label="计划开始时间"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{scope.row.planStartTime ? scope.row.planStartTime : '-'}}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="realityStartTime"
              label="实际开始时间"
              width="150"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.realityStartTime">
                  <span
                    v-if="scope.row.overTimeNumStart < 1 && scope.row.overTimeNumStart !== ''"
                    class="status circle-green"
                  ></span>
                  <span
                    v-else-if="scope.row.overTimeNumStart > 0"
                    class="status circle-red"
                  ></span>
                  {{scope.row.realityStartTime}}
                  {{scope.row.overTimeNumStart > 0?`(超${scope.row.overTimeNumStart}天)`:''}}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="brandName"
              label="计划完成时间"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{scope.row.planFinishTime ? scope.row.planFinishTime : '-'}}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="brandName"
              label="实际完成时间"
              width="150"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.realityFinishTime">
                  <span
                    v-if="scope.row.overTimeNumEnd===0"
                    class="status circle-green"
                  ></span>
                  <span
                    v-else-if="scope.row.overTimeNumEnd>0"
                    class="status circle-red"
                  ></span>
                  {{scope.row.realityFinishTime}}
                  {{scope.row.overTimeNumEnd>0?`(超${scope.row.overTimeNumEnd}天)`:''}}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              width="170"
            >
              <template slot-scope="scope">
                <span>{{scope.row.createTime ? scope.row.createTime : '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="150"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  @click="toFollowDetail(scope.row)"
                  type="text"
                  size="small"
                >查看</el-button>
                <!-- v-if="hasPermissionFollow && scope.row.isShow === 0 && scope.row.taskState !== '已签约'" -->
                <el-button
                  v-if="hasPermissionFollow && scope.row.taskState !== '已签约'"
                  @click="editFollowStatus(scope.row)"
                  type="text"
                  size="small"
                >跟进</el-button>
                <el-button
                  v-if="!hasPermissionFollow && scope.row.taskState !== '未发布'"
                  @click="hasnotPermission"
                  type="text"
                  size="small"
                >跟进</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          class="no-recommend-brand"
          style="padding-top: 40px;"
          v-if="brandList.dataList && brandList.dataList.length === 0"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂无相关数据</p>
        </div>
        <div
          v-if="brandList.total > 10"
          class="w-page-sty"
        >
          <el-pagination
            background
            @current-change="pageChange"
            layout="prev, pager, next"
            :page-size="brandList.pageSize"
            :total="brandList.total"
            :current-page="pageNum"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="400px"
        top="350px"
        :show-close="false"
        custom-class="w-block-dialog"
        :before-close="handleClose"
      >
        <div v-if="dialogVisible">
          <brandInvestment
            :projectId="projectId"
            :dialogType="dialogType"
            :selectTaskIds="selectTaskIds"
            @handleClose="handleClose"
            @editStatusEmit="editStatusEmit"
          ></brandInvestment>
        </div>
      </el-dialog>
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="investmentShow"
        direction="rtl"
      >
        <div v-if="investmentShow">
          <edit-states
            :invesmtentItem="invesmtentItem"
            @hideInvestmentEmit="hideInvestmentEmit"
          ></edit-states>
        </div>
      </el-drawer>
      <div v-if="maskShow">
        <add-mask
          v-if="projectId"
          :invesmtentItem="invesmtentItem"
          :projectId="projectId"
          :projectName="projectName"
          :maskEdit="maskEdit"
          @hideSingleMask="hideSingleMask"
        ></add-mask>
      </div>
      <!-- <div v-if="taskBrand">
        <inves-task
          v-if="projectId"
          :projectName="projectName"
          :selectProjectId="projectId"
          @hideTaskFunc="hideTaskFunc"
        ></inves-task>
      </div> -->
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="isInvestment"
        :before-close="beforeCloseBrandFollow"
        direction="rtl"
      >
        <div v-if="isInvestment && editType">
          <brandFollow
            @showAddBrand="showAddBrand"
            @selectProjectIdEmit="selectProjectIdEmit"
            @releaseBrandSuccessEmit="releaseBrandSuccessEmit"
            @hideBrandFollowEmit="hideBrandFollowEmit"
            :projectId="projectId"
            :editType="editType"
          ></brandFollow>
        </div>
        <el-drawer
          class="black-drawer"
          size="907px"
          :with-header="false"
          :append-to-body="true"
          :before-close="hideAddBrand"
          :visible.sync="addBrandSync"
        >
          <div v-if="projectIdcheck > 0">
            <addBrand
              @closeAddBrand="closeAddBrand"
              @addBrandEmit="addBrandEmit"
              :projectIdcheck="projectIdcheck"
              :editType="editType"
            ></addBrand>
          </div>
        </el-drawer>
      </el-drawer>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import editStates from '@/views/workBench/components/investmentEditStates.vue'
import addMask from '@/views/workBench/components/addSingleMask.vue'
import brandInvestment from '@/views/workBench/components/brandInvestmentPopup.vue'
import brandFollow from '@/views/workBench/components/brandFollow.vue'
import addBrand from '@/views/workBench/components/addBrand.vue'
import Bus from '@/utils/bus.js'
import { powerAuth } from '@/utils'
export default {
  components: {
    editStates,
    addMask,
    brandInvestment,
    brandFollow,
    addBrand
  },
  data () {
    return {
      loading: true,
      pageNum: 1,
      brandName: '',
      selectFloor: [],
      floorList: [],
      foundDate: '',
      followList: [],
      followName: '',
      followId: '',
      filterFloorArr: [],
      building: '',
      floor: '',
      berthNo: '',
      berthStr: '',
      formatList: [],
      creatTime: '',
      createTimeBeg: '',
      createTimeEnd: '',
      brandList: {},
      formatStr: '',
      statesStr: '',
      states: [{
        label: '全部',
        value: ''
      }, {
        label: '待跟进',
        value: '待跟进'
      }, {
        label: '已联系',
        value: '已联系'
      }, {
        label: '已看场',
        value: '已看场'
      }, {
        label: '已报价',
        value: '已报价'
      }, {
        label: '已预定',
        value: '已预定'
      }, {
        label: '已签约',
        value: '已签约'
      }, {
        label: '已搁置',
        value: '已搁置'
      }, {
        label: '无意向',
        value: '无意向'
      }],
      data: {},
      selectProjectObj: {},
      invesmtentItem: {},
      maskEdit: 0,
      investmentShow: false,
      maskShow: false,
      userAuthorities: [],
      hasPermission: true, // 权限
      hasPermissionAdd: true, // 添加：指“添加招商任务”的操作；d
      hasPermissionDel: true, // 删除：指“删除招商计划”的操作；
      hasPermissionFollow: true, // 跟进：指“跟进”状态修改的操作；d
      hasPermissionEditfollow: true, // 修改跟进人：指“修改跟进人”的操作；
      hasPermissionLeadingout: true, // 导出：指“导出”的操作；d
      isInvestment: false,
      addBrandSync: false,
      modulesList: [],
      approval: '',
      approvalLsit: [{
        value: '',
        label: '全部'
      }, {
        value: '审批中',
        label: '审批中'
      }, {
        value: '审批完成',
        label: '审批完成'
      }, {
        value: '-',
        label: '-'
      }],
      storeType: -1,
      storeDataList: ['普通店', '主力店', '次主力店'],
      storeTypeList: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '主力店',
          value: 1
        },
        {
          label: '次主力店',
          value: 2
        },
        {
          label: '普通店',
          value: 0
        }
      ],
      timeType: 0,
      timeTypeList: [
        {
          label: '创建时间',
          value: 0
        }, {
          label: '计划开始时间',
          value: 1
        }, {
          label: '实际开始时间',
          value: 2
        }, {
          label: '计划完成时间',
          value: 3
        }, {
          label: '实际完成时间',
          value: 4
        }
      ],
      timeValue: [],
      timeValuePlaceHolder: [
        '请选择招商发布时间段',
        '请选择计划开始时间段',
        '请选择实际开始时间段',
        '请选择计划完成时间段',
        '请选择实际完成时间段'
      ],
      overTime: 0,
      overtimeList: [
        {
          label: '全部',
          value: 0
        }, {
          label: '超期开始',
          value: 1
        }, {
          label: '超期未开始',
          value: 2
        }, {
          label: '超期完成',
          value: 3
        }, {
          label: '超期未完成',
          value: 4
        }
      ],
      dialogVisible: false,
      dialogTitle: '',
      dialogType: 0,
      selectTaskIds: [],
      projectIdcheck: 0,
      editType: 1
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  props: {
    projectId: {
      type: Number
    },
    projectName: {
      type: String
    }
  },
  watch: {
    projectId (o) {
      this.pageNum = 1
      this.projectIdcheck = o
      this.getInvestmentList()
      this.getFollow()
      this.getFilterFloorFunc()
    }
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 217
    })
    if (this.userAuthorities.length) {
      if (this.projectId) {
        this.projectIdcheck = this.projectId
        this.getInvestmentList()
        this.getFollow()
        this.getFilterFloorFunc()
      }
      this.getListIndustry()
      this.getModularListFunc()
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.hasPermissionAdd = this.userAuthorities[0].func.indexOf('add') !== -1
      this.hasPermissionDel = this.userAuthorities[0].func.indexOf('del') !== -1
      this.hasPermissionFollow = this.userAuthorities[0].func.indexOf('follow') !== -1
      this.hasPermissionEditfollow = this.userAuthorities[0].func.indexOf('editfollow') !== -1
      this.hasPermissionLeadingout = this.userAuthorities[0].func.indexOf('leadingout') !== -1
    } else {
      this.hasPermission = false
      this.loading = false
    }
  },
  methods: {
    setClassName (row) {
      // 通过自己的逻辑返回一个class或者空
      return row.row.expand ? '' : 'disabled-expand'
    },
    getSaveHistory (e) {
      const params = {
        name: '/berthTask/list'
      }
      this.axios.post(api.getListParamsLog, params)
        .then((res) => {
          console.log(res)
        })
    },
    refresh (e) {
      console.log(e)
    },
    getModularListFunc () { // 获取工作台的菜单列表，这里用于判断有无权限调整相关页面
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    getFilterFloorFunc (id) {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.filterFloorArr = res.data.data
        })
    },
    getListIndustry () {
      this.axios.post(api.getListIndustry1).then((res) => {
        if (res.data.code === 0) {
          res.data.data.unshift(
            {
              name: '全部',
              id: '',
              value: '全部'
            }
          )
        }
        this.formatList = res.data.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      })
    },
    getFollow () {
      this.axios.post(api.getFollowList, { projectId: this.projectId })
        .then((res) => {
          this.followList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
          this.followList.unshift({ label: '全部', value: '' })
        })
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.projectId
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    changeFollow (val) {
      this.followId = val
    },
    changeFormat (val) {
      this.formatStr = val
    },
    changeStates (val) {
      this.statesStr = val
    },
    changeCreat (val) {
      this.creatTime = val
      // this.createTimeBeg = this.creatTime[0]
      // this.createTimeEnd = this.creatTime[1]
      // console.log(this.creatTime)
    },
    changeFloor (val) {
      this.building = ''
      this.floor = ''
      this.berthNo = ''
      this.building = val[0]
      if (val[1]) {
        this.floor = val[1]
        if (val[2]) {
          this.berthNo = val[2]
          this.berthStr = this.$refs.myCascader.currentLabels[2]
        }
      }
    },
    addTaskBrand (type) {
      if (type === 1) {
        if (!powerAuth(217, 'pubplan')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      } else {
        if (!powerAuth(217, 'addfollow')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
      }
      this.$store.dispatch('setInvestmentBrandList', [])
      this.editType = type
      this.isInvestment = true
    },
    hideAddBrand () {
      this.addBrandSync = false
    },
    selectProjectIdEmit (id) {
      this.projectIdcheck = id
    },
    closeAddBrand () {
      this.addBrandSync = false
    },
    addBrandEmit () {
      this.addBrandSync = false
    },
    showAddBrand () {
      this.addBrandSync = true
    },
    hideBrandFollowEmit () {
      Bus.$emit('removeAddBrandListBusEmit')
      this.isInvestment = false
    },
    beforeCloseBrandFollow () {
      Bus.$emit('removeAddBrandListBusEmit')
      this.isInvestment = false
    },
    releaseBrandSuccessEmit () {
      this.isInvestment = false
      this.getInvestmentList()
    },
    handleSelectionChange (val) {
      this.selectTaskIds = val.map(item => {
        return item.id
      })
    },
    dialogShow (type) {
      if (type === 0) {
        if (!powerAuth(217, 'setfollow')) {
          this.hasnotPermission()
          return false
        }
      } else {
        if (!powerAuth(217, 'brandtasktime')) {
          this.hasnotPermission()
          return false
        }
      }
      if (this.selectTaskIds.length === 0) {
        this.$message({
          type: 'warning',
          message: '请勾选要操作的任务！'
        })
        return
      }
      this.dialogType = type
      if (type === 0) {
        this.dialogTitle = '指定跟进人'
      } else if (type === 1) {
        this.dialogTitle = '编辑计划时间'
      }
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    editStatusEmit () {
      this.dialogVisible = false
      this.getInvestmentList()
    },
    getInvestmentList () {
      if (JSON.parse(localStorage.getItem('saveScreen'))) {
        this.timeValue = []
        this.data = JSON.parse(localStorage.getItem('saveScreen'))
        this.brandName = this.data.brandName
        this.formatStr = this.data.industry
        this.followId = this.data.followUserId
        if (this.data.building) {
          this.selectFloor.push(this.data.building)
          if (this.data.floor) {
            this.selectFloor.push(this.data.floor)
            if (this.data.berthId) {
              this.selectFloor.push(this.data.berthId)
            }
          }
        } else {
          this.selectFloor = []
        }
        this.statesStr = this.data.taskState
        this.approval = this.data.approvalStatus
        this.storeType = this.data.mainStore
        // if (this.data.mainStore === '') {
        //   this.storeType = -1
        // }
        this.overTime = this.data.timeOutType
        this.timeType = this.data.filterType
        this.data.pageSize = 10
        this.isLike = 0
        if (this.data.filterTimeStart) {
          this.timeValue.push(this.data.filterTimeStart, this.data.filterTimeEnd)
        } else {
          this.timeValue = []
        }
        this.data.pageNum = this.pageNum
      } else {
        this.data.pageSize = 10
        this.data.pageNum = this.pageNum
      }
      this.data.projectId = this.projectId
      this.axios.post(api.getBerthTaskList, this.data)
        .then((res) => {
          this.brandList = res.data.data
          this.brandList.dataList = res.data.data.dataList.map((item) => {
            item.overTimeNumStart = ''
            item.overTimeNumEnd = ''
            if (item.realityStartTime && item.planStartTime) {
              item.overTimeNumStart = dayjs(item.realityStartTime).diff(dayjs(item.planStartTime), 'day')
            }
            if (item.realityFinishTime && item.planFinishTime) {
              item.overTimeNumEnd = dayjs(item.realityFinishTime).diff(dayjs(item.planFinishTime), 'day')
            }
            if (item.children && item.children.length) {
              item.expand = true
            } else {
              item.expand = false
            }
            if (item.taskState === '待跟进') {
              item.color = '#FFA134'
            } else if (item.taskState === '已联系') {
              item.color = '#3494FF'
            } else if (item.taskState === '已看场') {
              item.color = '#26CBE9'
            } else if (item.taskState === '已报价') {
              item.color = '#FEF054'
            } else if (item.taskState === '已预定') {
              item.color = '#FE7429'
            } else if (item.taskState === '已签约') {
              item.color = '#16DA64'
            } else if (item.taskState === '已搁置') {
              item.color = '#C750FF'
            } else {
              item.color = '#999999'
            }
            return item
          })
          this.loading = false
        })
    },
    searchBtn () {
      this.data.projectId = this.projectId
      this.data.brandName = this.brandName
      this.data.approvalStatus = this.approval
      this.data.industry = this.industry === '全部' ? '' : this.formatStr
      this.data.taskState = this.statesStr
      this.data.followUserId = this.followId
      if (this.selectFloor[0]) {
        this.data.building = this.selectFloor[0]
        if (this.selectFloor[1]) {
          this.data.floor = this.selectFloor[1]
          if (this.selectFloor[2]) {
            this.data.berthId = this.selectFloor[2]
          } else {
            this.data.berthId = ''
          }
        } else {
          this.data.floor = ''
        }
      } else {
        this.data.building = ''
        this.data.floor = ''
        this.data.berthId = ''
      }
      this.data.timeOutType = this.overTime
      this.data.filterType = this.timeType
      this.data.mainStore = this.storeType === -1 ? '' : this.storeType
      this.data.pageSize = 10
      this.pageNum = 1
      this.isLike = 0
      if (this.timeValue && this.timeValue.length) {
        this.data.filterTimeStart = this.timeValue[0]
        this.data.filterTimeEnd = this.timeValue[1]
        // this.timeValue.push(this.data.filterTimeStart, this.data.filterTimeEnd)
      } else {
        this.data.filterTimeStart = ''
        this.data.filterTimeEnd = ''
      }
      localStorage.setItem('saveScreen', JSON.stringify(this.data))
      this.getInvestmentList()
    },
    pageChange (val) {
      this.pageNum = val
      this.getInvestmentList()
    },
    editFollowStatus (item) {
      if (!powerAuth(217, 'follow')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.invesmtentItem = item
      this.invesmtentItem.brand = item
      this.invesmtentItem.task = item
      this.invesmtentItem.taskId = item.task.id
      if (item.berths && item.berths.length > 0) {
        this.invesmtentItem.floor = item.berths[0].floor
        this.invesmtentItem.berthNo = item.berths[0].berthNo
        this.invesmtentItem.berthId = item.berths[0].berthId
      }
      this.investmentShow = true
    },
    hideInvestmentEmit (type) {
      if (type) {
        this.getInvestmentList()
      }
      this.investmentShow = false
      // document.body.style.overflowY = 'auto'
    },
    hideSingleMask (type) {
      if (type) {
        this.getInvestmentList()
      }
      this.maskShow = false
      document.body.style.overflowY = 'auto'
    },
    hideTaskFunc () {
      this.taskBrand = false
      this.getInvestmentList()
      document.body.style.overflowY = 'auto'
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    exportInvestment () {
      if (!powerAuth(217, 'leadingout')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.loading = true
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      // demo
      this.exportPlan().then(res => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const fileName = '【' + this.projectName + '】' + '招商品牌管理' + '【' + year + '-' + month + '-' + day + '】' + '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
          this.loading = false
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
          this.loading = false
        }
      })
    },
    exportPlan () {
      const data = {
        berthId: this.berthNo,
        berthNo: this.berthStr,
        brandName: this.brandName,
        createTimeBeg: this.creatTime ? this.creatTime[0] : '',
        createTimeEnd: this.creatTime ? this.creatTime[1] : '',
        floor: this.floor,
        followUserId: this.followId,
        industry: this.industry === '全部' ? '' : this.industry,
        pageNum: this.pageNum,
        pageSize: 10,
        projectId: this.projectId,
        taskState: this.statesStr
      }
      return this.axios({ // 用axios发送post请求
        method: 'post',
        url: api.exportInvestement, // 请求地址
        data: data, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    toFollowDetail (item) {
      // if (!powerAuth(217)) {
      //   this.$message.error({
      //     message: '暂无权限，如需开通权限，请联系管理员开通'
      //   })
      //   return false
      // }
      this.$router.push({
        path: '/investmentFollowDetail?id=' + item.id
      })
    },
    toBrandDetail (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    handleTimeValue (val) {
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-follow-manage-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  margin-right 24px
  .w-follow-head
    display flex
    height 34px
    .w-follow-btn
      margin-left auto
      span
        text-align center
        display inline-block
        line-height 34px
      .w-drop-btn
        width 140px
        border 1px solid #FFA134
        border-radius 3px
        font-size 16px
        color #FFA134
        cursor pointer
        .icon-add:before
          font-size 15px
          color #FFA134
      .w-export-btn
        display inline-block
        line-height 32px
        width 110px
        border 1px solid rgba(255, 255, 255, 0.5)
        border-radius 3px
        font-size 16px
        color rgba(255, 255, 255, 1)
        margin-left 20px
        cursor pointer
        .icon-export
          margin-right 10px
  .w-berth-screen
    // height 270px
    padding-bottom 24px
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    padding-left 40px
    padding-top 24px
    box-sizing border-box
    .w-screen-list
      max-width 1690px
      .w-screen-top
        display flex
        // margin-top 35px
        .w-input-item
          display flex
          align-items center
          margin-right 50px
          .el-input__inner
            font-size 14px
          .w-type-name
            display inline-block
            margin-right 4px
            color #fff
            font-size 14px
            font-weight 400
      .w-screen-bottom
        display flex
        margin-top 35px
        .time-select>>>
          position relative
          .time-value-placeholder
            color #c0c4cc
            font-size 12px
            display inline-block
            position absolute
            left 170px
          .el-select
            .el-input__inner
              width 130px !important
          .el-date-editor
            width 280px !important
            .el-range-separator
              color #fff
            .el-range-input:first-child
              width 100% !important
        .w-input-item
          display flex
          align-items center
          margin-right 50px
          .w-type-name
            display inline-block
            margin-right 4px
            color #fff
            font-size 14px
            font-weight 400
  .w-singed-table
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 15px
    box-sizing border-box
    padding-bottom 35px
    .table-content
      padding-left 32px
    // 表格样式
    & >>> .el-table
      font-size 14px
      font-family Microsoft YaHei
      font-weight 400
    & >>> .el-table th
      color #B2B3BD !important
      font-size 14px
      font-weight 400
    & >>> .el-table tr
      color #808191 !important
    // 图标
    & >>> .el-table__expand-icon
      transform rotate(0deg)
      color #FFF
    & >>> .el-table__expand-icon--expanded
      transform rotate(90deg)
    & >>> .el-table__fixed-right::before
      display none
    // 展开行
    & >>> .expanded
      background-color #1E1E23 !important
    & >>> .expanded td
      border-bottom none !important
    & >>> .el-table__expanded-cell
      padding 16px 0 16px 88px
      background-color #1E1E23
    & >>> .el-table__expanded-cell td
      padding 0
    & >>> .el-table__expanded-cell:hover
      background-color #1E1E23 !important
    & >>> .el-form
      padding-left 170px
    & >>> .el-form-item__label
      padding 0
      color #808191
    & >>> .el-form-item__content
      color #FFF
      min-width 100px
    & >>> .el-form-item
      margin-right 20px
      margin-bottom 0
    & >>> .el-table__empty-block
      display none
    .el-button+.el-button
      margin-left 24px
    & >>> .el-table-column--selection .cell
      padding-right 0
      padding-left 14px !important
    .status
      display inline-block
      width 10px
      height 10px
      border-radius 5px
      background #FFA134
      margin-right 5px
    .header-content
      display flex
      .btns
        margin-left auto
        margin-right 24px
        margin-top 14px
        span
          margin-left 32px
        .setting-follow-user
          font-size 16px
          font-weight 400
          color #fff
          cursor pointer
          &:before
            margin-right 6px
            font-family 'iconfont'
            content '\e65b'
          &:hover
            color #f89407
        .setting-brand-task
          font-size 16px
          font-weight 400
          color #fff
          cursor pointer
          &:before
            margin-right 6px
            font-family 'iconfont'
            content '\e65a'
          &:hover
            color #f89407
        .setting-brand-follow
          font-size 16px
          font-weight 400
          color #fff
          cursor pointer
          &:before
            margin-right 6px
            font-family 'iconfont'
            content '\e657'
          &:hover
            color #f89407
        .setting-plan-time
          font-size 16px
          font-weight 400
          color #fff
          cursor pointer
          &:before
            margin-right 6px
            font-family 'iconfont'
            content '\e659'
          &:hover
            color #f89407
        .setting-export
          font-size 16px
          font-weight 400
          color #fff
          cursor pointer
          &:before
            margin-right 6px
            font-family 'iconfont'
            content '\e658'
          &:hover
            color #f89407
    .table-content
      margin-top 14px
    .is-scroll-x
      min-height 350px
      width 100%
      overflow-x auto
      overflow-y hidden
      padding-bottom 30px
      &::-webkit-scrollbar
        height 6px
.states-wrap
  position absolute
  left 0px
  top 10px
  width 75px
  background rgba(39, 41, 48, 1)
  box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
  z-index 111111
  color #ffffff
  p
    height 26px
    line-height 26px
    text-align center
    &:hover
      color #FFA134
.w-singed-table tbody
  tr:first-child, tr:nth-child(2), tr:nth-child(3)
    .states-wrap
      top 10px !important
.w-singed-table tbody
  tr:last-child, tr:nth-last-child(2), tr:nth-last-child(3)
    .states-wrap
      top -180px
.circle-green
  background #70b603 !important
.circle-red
  background #d9001b !important
.brandName, .industry, .lastFollowMsg
  display inline-block
.brandName
  width 160px
  cursor pointer
  color #fff
  text-decoration underline
  &:hover
    color #F89407
.industry
  width 100px
.lastFollowMsg
  width 155px
.w-follow-manage-wrap
  .w-berth-screen
    .w-screen-list
      .w-screen-bottom
        .time-select
          .time-value-placeholder
            font-size 14px
</style>
